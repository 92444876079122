<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Cashbacks Transaction</h2>
      </div>
      <!-- <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
            placeholder="Search for cashback, reference"
            v-model="searchQuery"
          />
          <ion-icon
            name="search-outline"
            class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
            style="top: 50%; transform: translateY(-50%);"
          ></ion-icon>
        </div>
      </div> -->
      <div class="col-span-1">
        <search-form
          placeholder="Search user name, merchant name, refererence number...."
          class="mb-5"
          v-model="cashbacks.searchQuery"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="cashbacks.url"
            :ajax="true"
            :ajax-pagination="true"
            :columns="cashbacks.columns"
            :query="cashbacks.searchQuery"
            :fillable="false"
            ref="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cashbacks: this.$options.resource([], {
        url: `${this.$baseurl}/admin/transactions/cashback`,
        searchQuery: '',
        columns: [
          {
            name: 'reference',
            th: 'Reference'
          },
          {
            name: 'owner',
            th: 'User',
            render: ({ owner }) => `${owner?.name} ${owner?.last_name}`
          },
          {
            name: 'commission',
            th: 'Commission',
            render: cashback =>
              cashback?.type === 'percentage'
                ? `${cashback?.commission}%`
                : cashback?.commission
          },
          {
            name: 'amount',
            th: 'Amount',
            render: cashback =>
              this.$options.filters.formatAmount(cashback?.amount)
          },
          {
            name: 'cashback',
            th: 'Cashback',
            render: cashback =>
              this.$options.filters.formatAmount(cashback?.cashback)
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: cashback =>
              this.$moment(cashback?.created_at).format('dddd, MMMM Do YYYY')
          }
        ]
      })
    };
  }
};
</script>
